import {
  allUploadProgressSelector,
  hasUploadsSelector,
  setUploadsResumedAction,
  uploadArraySelector,
  uploadByIdSelector,
  uploaderIsUploadingSelector,
  uploadingSpeedSelector,
} from '@air/redux-uploader';
import {
  useDeleteAllUploads,
  useDeleteUpload,
  UseDeleteUploadParams,
  usePauseAllUploads,
  usePauseUploadById,
  useResumeAllUploads,
  useRetryAllUploads,
} from '@air/upload-utils';
import { memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FileStatusTrackingPane,
  FileStatusTrackingPaneProps,
  FileTrackingItem,
} from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { useCalculateUploadSpeed } from '~/components/Upload/hooks/useCalculateUploadSpeed';
import { convertUploadToFileTrackingPaneItem } from '~/components/Upload/utils/convertUploadToFileTrackingPaneItem';
import { UPLOADER_PANE } from '~/constants/testIDs';
import useWakelock from '~/hooks/useWakelock';
import { useAirStore } from '~/utils/ReduxUtils';

import { useUploadCancelAlertOnUnload } from './hooks/useUploadCancelAlertOnUnload';
import { useUploadEstimatedTime } from './hooks/useUploadEstimatedTime';
import { useUploadsOfflineListener } from './hooks/useUploadsOfflineListener';
import { useGetUploadHeaderTitles } from './useGetUploadHeaderTitles';

export interface UploadsTrackingPaneProps {
  summaryLocation: string | undefined;
  onCancelAllUploads?: () => void;
  onDeleteUpload?: UseDeleteUploadParams['onDelete'];
}

export const UploadsTrackingPane = memo(
  ({ summaryLocation, onCancelAllUploads, onDeleteUpload }: UploadsTrackingPaneProps) => {
    const dispatch = useDispatch();
    const store = useAirStore();

    useUploadCancelAlertOnUnload();
    useCalculateUploadSpeed();
    const { estimatedTime } = useUploadEstimatedTime();
    const uploadSpeed = useSelector(uploadingSpeedSelector);
    const { isOnline } = useUploadsOfflineListener();

    const hasUploads = useSelector(hasUploadsSelector);

    const isUploading = useSelector(uploaderIsUploadingSelector);
    useWakelock(isUploading);

    const uploads = useSelector(uploadArraySelector);
    const uploadProgress = useSelector(allUploadProgressSelector);
    const uploadsItems = uploads.map((upload) =>
      convertUploadToFileTrackingPaneItem(upload, uploadProgress[upload.id]),
    );

    const { pauseUploadById } = usePauseUploadById();
    const { deleteUpload } = useDeleteUpload({ onDelete: onDeleteUpload });
    const { deleteAllUploads } = useDeleteAllUploads({ onDelete: onDeleteUpload });
    const { pauseAllUploads } = usePauseAllUploads();
    const { resumeAllUploads } = useResumeAllUploads();
    const { retryAllUploads } = useRetryAllUploads();

    const { getUploadHeaderTitles } = useGetUploadHeaderTitles();

    const cancelItem = useCallback(
      (item: FileTrackingItem) => {
        const upload = uploadByIdSelector(store.getState(), item.id);
        if (upload) {
          deleteUpload(upload);
        }
      },
      [deleteUpload, store],
    );

    const pauseItem = useCallback(
      (item: FileTrackingItem) => {
        pauseUploadById(item.id);
      },
      [pauseUploadById],
    );

    const resumeItem = useCallback(
      (item: FileTrackingItem) => {
        dispatch(setUploadsResumedAction({ uploadIds: [item.id] }));
      },
      [dispatch],
    );

    const tryToCancelAll = useCallback(() => {
      if (onCancelAllUploads) {
        onCancelAllUploads();
      } else {
        deleteAllUploads();
      }
    }, [deleteAllUploads, onCancelAllUploads]);

    const getMemoedUploadHeaderTitles: FileStatusTrackingPaneProps['getStatusTitles'] = useCallback(
      (params) =>
        getUploadHeaderTitles({
          estimatedCompletionTime: estimatedTime,
          summaryLocation,
          isOnline,
          speed: uploadSpeed,
        })(params),
      [estimatedTime, getUploadHeaderTitles, isOnline, summaryLocation, uploadSpeed],
    );

    return hasUploads ? (
      <FileStatusTrackingPane
        testId={UPLOADER_PANE}
        onRetryItem={resumeItem}
        onRetryAll={retryAllUploads}
        onCancelItem={cancelItem}
        onPauseItem={pauseItem}
        onResumeItem={isOnline ? resumeItem : undefined}
        onPauseAll={pauseAllUploads}
        onResumeAll={isOnline ? resumeAllUploads : undefined}
        onCancelAll={tryToCancelAll}
        onDismiss={tryToCancelAll}
        items={uploadsItems}
        getStatusTitles={getMemoedUploadHeaderTitles}
      />
    ) : null;
  },
);

UploadsTrackingPane.displayName = 'UploadsTrackingPane';
