import { formatBytes } from '@air/utilities';
import { isFinite, isNumber } from 'lodash';
import pluralize from 'pluralize';
import { useCallback } from 'react';

import { SimpleStatus } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/FileStatusTrackingPaneHeader';
import { FileTrackingStatusHeaderTitles, HeaderStatusParams } from '~/components/FileStatusTrackingPane/shared';

export const useGetUploadHeaderTitles = () => {
  const getProgressingSubtitle = useCallback((estimatedCompletionTime: number | null, speed: number | null) => {
    if (estimatedCompletionTime && isNumber(speed) && isFinite(speed)) {
      const speedText = `${formatBytes(speed, { numberOfDecimals: 2 })}/s`;

      if (isFinite(estimatedCompletionTime)) {
        const seconds = estimatedCompletionTime / 1000;
        const minutes = seconds / 60;
        const hours = minutes / 60;
        const days = hours / 24;

        let remainingTime = '';

        if (days >= 1) {
          remainingTime = `${pluralize('day', Math.floor(days), true)}`;

          const remainingHours = hours % 24;

          if (remainingHours) {
            remainingTime += ` ${Math.floor(remainingHours)} hr`;
          }
        } else if (hours >= 1) {
          remainingTime = `${Math.floor(hours)} hr`;

          const remainingMinutes = minutes % 60;

          if (remainingMinutes) {
            remainingTime += ` ${Math.floor(remainingMinutes)} min`;
          }
        } else if (minutes >= 1) {
          remainingTime = `${Math.floor(minutes)} min`;

          const remainingSeconds = seconds % 60;

          if (remainingSeconds) {
            remainingTime += ` ${Math.floor(remainingSeconds)}s`;
          }
        } else {
          if (Math.floor(seconds) <= 0) {
            return 'Finishing up...';
          } else {
            remainingTime = `${Math.floor(seconds)}s`;
          }
        }

        return `${remainingTime} · ${speedText}`;
      }
      return 'Starting';
    }
    return '';
  }, []);

  const getUploadHeaderTitles = useCallback(
    ({
      estimatedCompletionTime,
      summaryLocation,
      isOnline,
      speed,
    }: {
      estimatedCompletionTime: number | null;
      summaryLocation: string | undefined;
      isOnline: boolean;
      speed: number | null;
    }) =>
      ({
        pausedCount,
        erroredCount,
        completedCount,
        progressingCount,
      }: HeaderStatusParams): FileTrackingStatusHeaderTitles => {
        const pausedTitle = {
          title: `Upload paused`,
          subtitle: isOnline ? `${pluralize('items', pausedCount, true)} remaining` : 'No internet connection',
        };

        const progressingTitle = {
          title: `Uploading ${pluralize('items', progressingCount, true)}`,
          subtitle: getProgressingSubtitle(estimatedCompletionTime, speed),
        };

        const erroredTitle = {
          title: `Upload incomplete`,
          subtitle: `${pluralize('items', erroredCount, true)} failed`,
        };

        const completedSubtitle = `${pluralize('items', completedCount, true)}`;

        const completedTitle = {
          title: `Upload complete`,
          subtitle: summaryLocation ? `${completedSubtitle} to ${summaryLocation}` : completedSubtitle,
        };

        return {
          [SimpleStatus.errored]: erroredTitle,
          [SimpleStatus.paused]: pausedTitle,
          [SimpleStatus.progressing]: progressingTitle,
          [SimpleStatus.completed]: completedTitle,
        };
      },
    [getProgressingSubtitle],
  );

  return {
    getUploadHeaderTitles,
  };
};
