import { ThreeBirdsFlyingVideo } from './ThreeBirdsFlyingVideo';

export const DropzoneOverlay = () => {
  return (
    <div className="fixed inset-0 z-10 flex flex-col items-center justify-center bg-grey-1 text-grey-12">
      <ThreeBirdsFlyingVideo />
      <h1 className="mt-24 font-semibold text-grey-12">Drop files anywhere to upload</h1>
    </div>
  );
};
