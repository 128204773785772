import { Task, TaskScheduler } from '@air/classes-task-scheduler';
import {
  isFinishedUploadingSelector,
  nextUploadIdSelector,
  setAllFilesUploaded,
  Upload,
  uploadByIdSelector,
  useUploaderStore,
} from '@air/redux-uploader';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface UseUploadManagerProps {
  getUploadTasks: (params: { upload: Upload }) => Task[];
}

/**
 * This hook starts uploads if there is any new and sends 'isFinished' event when everything is uploaded
 */
export const useUploadManager = ({ getUploadTasks }: UseUploadManagerProps) => {
  const store = useUploaderStore();
  const dispatch = useDispatch();
  const nextUploadId = useSelector(nextUploadIdSelector);
  const isFinished = useSelector(isFinishedUploadingSelector);

  const getUploadTasksRef = useRef(getUploadTasks);
  getUploadTasksRef.current = getUploadTasks;

  const SchedulerRef = useRef<TaskScheduler | undefined>();
  useEffect(() => {
    SchedulerRef.current = new TaskScheduler(5);
  }, []);

  useEffect(() => {
    if (nextUploadId) {
      const nextUpload = uploadByIdSelector(store.getState(), nextUploadId);
      if (nextUpload) {
        const tasks = getUploadTasksRef.current({ upload: nextUpload });
        SchedulerRef.current?.addTasks(tasks);
      }
    }
  }, [nextUploadId, store]);

  useEffect(() => {
    if (isFinished) {
      SchedulerRef.current?.reset();
      dispatch(setAllFilesUploaded());
    }
  }, [dispatch, isFinished]);
};
