import { formatBytes } from '@air/utilities';
import { isUndefined } from 'lodash';

export const getSubtitleWithProgress = ({
  sizeInBytes,
  title,
  uploadedBytes,
}: {
  uploadedBytes?: number;
  sizeInBytes?: number;
  title: string;
}) => {
  if (sizeInBytes && !isUndefined(uploadedBytes)) {
    return `${formatBytes(uploadedBytes ?? 0, {
      numberOfDecimals: 2,
    })}/${formatBytes(sizeInBytes, {
      numberOfDecimals: 2,
    })} · ${title}`;
  }
  return title;
};
