import { sleep } from '@air/call-with-retry';
import { NetworkStatusInfo } from '@air/classes-network-status-info';
import { useConnectionStatusListener } from '@air/hook-use-connection-status-listener';
import {
  setUploadingSpeedAction,
  totalUploadedSizeSelector,
  uploaderIsUploadingSelector,
  uploadingSpeedSelector,
} from '@air/redux-uploader';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import KalmanFilter from '~/components/Upload/utils/KalmanFilter';
import { useAirStore } from '~/utils/ReduxUtils';

/** How much time we want to measure the speed att */
const DURATION_TO_MEASURE = 500;
/**
 * This is how often we want to re-run the speed check.
 * We take whatever the duration to measure is and add a few seconds.
 * */
const INTERVAL_DURATION = DURATION_TO_MEASURE + 200;
/** This is the weight we want to give to the moving average */
const MOVING_AVERAGE_WEIGHT = 10;

/**
 * This hook watches the currently uploading state and tries to measure how fast the uploading is currently going and updates Redux accordingly
 * with the number of bytes per second it's uploading
 */
export const useCalculateUploadSpeed = () => {
  const store = useAirStore();
  const dispatch = useDispatch();
  const isUploading = useSelector(uploaderIsUploadingSelector);
  const uploadedBytes = useSelector(totalUploadedSizeSelector);
  const [isOnline, setIsOnline] = useState(true);
  const [isCheckingIfOnline, setIsCheckingIfOnline] = useState(false);
  const currentKalmanStateRef = useRef<KalmanFilter | null>(null);
  const hasUploadedBytes = uploadedBytes > 0;

  useConnectionStatusListener((_isOnline) => {
    setIsOnline(_isOnline);
  });

  /**
   * We only want to try and measure the speed if the user is actually uploading files and some bytes have actually been uploaded
   * We don't want to start measuring when we're just fetching download URLs and stuff. We only want to measure when actual bytes are going across the network.
   */
  const shouldCheckSpeed = isUploading && hasUploadedBytes;

  const checkSpeed = useCallback(async () => {
    const previousSpeed = uploadingSpeedSelector(store.getState());

    /** get the bytes they've uploaded so far */
    const initialBytesUploaded = totalUploadedSizeSelector(store.getState());

    /* wait a few seconds */
    await sleep(DURATION_TO_MEASURE);

    const isStillUploading = uploaderIsUploadingSelector(store.getState());

    /** If the user isn't uploading anymore, don't continue */
    if (!isStillUploading) {
      console.info(`The user isn't uploading anymore so we're not finishing checkSpeed`);
      return;
    }

    /** get the new number of bytes they've uploaded */
    const updatedBytesUploaded = totalUploadedSizeSelector(store.getState());

    /** figure out the difference between the bytes they've uploaded previously and just now */
    const delta = updatedBytesUploaded - initialBytesUploaded;

    /** take difference and divide it by how long we measured */
    const bytesPerSecond = (delta / DURATION_TO_MEASURE) * 1000;

    /**
     * if the bytes per second is 0, the user's internet could be offline or they might just be fetching download URLs.
     * */
    if (bytesPerSecond <= 0) {
      console.info(`bytesPerSecond were ${bytesPerSecond}, so checking if still online!`);
      /**
       * Check if they are still online.
       * If they're offline, the uploads will get paused by useUploadsOfflineListener.
       * If they're online, the next interval check should get updated with new bytes
       * */
      setIsCheckingIfOnline(true);
      const isOnline = await NetworkStatusInfo.checkIfOnline();
      console.info(`User is ${isOnline ? 'online' : 'offline'}`);
      setIsCheckingIfOnline(false);
    } else {
      currentKalmanStateRef.current =
        currentKalmanStateRef.current ||
        new KalmanFilter({
          Q: 300, // As per my tests, Q=300 is a good value for a standard deviation of ~20%
          R: 1,
        });
      const speedWithMovingAverage =
        (bytesPerSecond + MOVING_AVERAGE_WEIGHT * (previousSpeed ?? bytesPerSecond)) / (MOVING_AVERAGE_WEIGHT + 1);
      const speed = currentKalmanStateRef.current.filter(speedWithMovingAverage);

      dispatch(
        setUploadingSpeedAction({
          speed,
        }),
      );
    }
  }, [dispatch, store]);

  useEffect(() => {
    let intervalId: null | number = null;

    /**
     * If the user is online, is actively uploading, and we're not fetching to see if they're online
     * do an immediate speed check and then set an interval for the speed check
     */
    if (shouldCheckSpeed && isOnline && !isCheckingIfOnline) {
      checkSpeed();

      intervalId = setInterval(() => {
        console.info('interval fired!');
        checkSpeed();
      }, INTERVAL_DURATION);
    }

    return () => {
      /**
       * on unmount, or when shouldCheckSpeed changes,
       * clear the speed check interval
       */
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [checkSpeed, dispatch, isCheckingIfOnline, isOnline, shouldCheckSpeed, store]);
};
