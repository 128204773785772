/**
 * 1-D Kalman filter implementation
 * We use this to smooth out the upload speed calculation
 *
 * This typscript conversion is based on the original implementation in kalmanjs:
 * @see https://github.com/wouterbulten/kalmanjs/blob/master/src/kalman.js
 */
export default class KalmanFilter {
  private R: number; // noise power desirable
  private Q: number; // noise power estimated
  private A: number; // state vector
  private B: number; // control vector
  private C: number; // measurement vector
  private cov: number;
  private x: number; // estimated signal without noise

  constructor({
    R = 1,
    Q = 1,
    A = 1,
    B = 0,
    C = 1,
  }: { R?: number; Q?: number; A?: number; B?: number; C?: number } = {}) {
    this.R = R;
    this.Q = Q;
    this.A = A;
    this.B = B;
    this.C = C;
    this.cov = NaN;
    this.x = NaN;
  }

  /**
   * Filter a new value
   */
  filter(measurement: number, control = 0): number {
    if (isNaN(this.x)) {
      this.x = (1 / this.C) * measurement;
      this.cov = (1 / this.C) * this.Q * (1 / this.C);
    } else {
      // Compute prediction
      const predX = this.predict(control);
      const predCov = this.uncertainty();

      // Kalman gain
      const K = predCov * this.C * (1 / (this.C * predCov * this.C + this.Q));

      // Correction
      this.x = predX + K * (measurement - this.C * predX);
      this.cov = predCov - K * this.C * predCov;
    }

    return this.x;
  }

  /**
   * Predict next value
   */
  predict(u = 0): number {
    return this.A * this.x + this.B * u;
  }

  /**
   * Return uncertainty of filter
   */
  uncertainty(): number {
    return this.A * this.cov * this.A + this.R;
  }

  /**
   * Return the last filtered measurement
   */
  lastMeasurement(): number {
    return this.x;
  }

  /**
   * Set measurement noise Q
   */
  setMeasurementNoise(noise: number): void {
    this.Q = noise;
  }

  /**
   * Set the process noise R
   */
  setProcessNoise(noise: number): void {
    this.R = noise;
  }
}
