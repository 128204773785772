import { isFinishedUploadingSelector } from '@air/redux-uploader';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useUploadCancelAlertOnUnload = () => {
  const isFinished = useSelector(isFinishedUploadingSelector);

  useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent) => {
      if (!isFinished) {
        event.preventDefault();
        return (event.returnValue = 'Are you sure?\nThis will cancel all assets that have not been uploaded yet.');
      }
    };

    window.addEventListener('beforeunload', handleBeforeunload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeunload);
    };
  }, [isFinished]);
};
