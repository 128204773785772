import { totalUploadSizeLeftSelector, uploadingSpeedSelector } from '@air/redux-uploader';
import { isNumber } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useUploadEstimatedTime = () => {
  const uploadSpeed = useSelector(uploadingSpeedSelector);
  const remainingBytesToUpload = useSelector(totalUploadSizeLeftSelector);

  const estimatedTime = useMemo(() => {
    return isNumber(uploadSpeed) && uploadSpeed > 0 ? (remainingBytesToUpload / uploadSpeed) * 1000 : null;
  }, [remainingBytesToUpload, uploadSpeed]);

  return {
    estimatedTime,
  };
};
