import { ConfirmationModal } from '@air/component-confirmation-modal';
import { InputFile } from '@air/redux-uploader';
import pluralize from 'pluralize';

export interface RestrictedCharacterReplacementModalProps {
  items: InputFile[];
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const RestrictedCharacterReplacementModal = ({
  items,
  onCancel,
  onClose,
  onConfirm,
}: AirModalProps<RestrictedCharacterReplacementModalProps>) => {
  const handleCancel = () => {
    onCancel && onCancel();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm();
    onClose();
  };

  return (
    <ConfirmationModal
      title={'Rename files?'}
      id="restricted-character-replacement"
      description={
        <>
          <span>There {pluralize('is', items.length)} </span>
          <span className="font-semibold">{pluralize('file', items.length, true)} </span>
          <span>
            that {pluralize('has', items.length)} the following restricted characters in{' '}
            {items.length > 1 ? 'their' : 'its'} {pluralize('name', items.length)}: forward slash (/), vertical bar (|),
            and colon (:). By clicking &quot;Rename files&quot;, these characters will be replaced with a dash (-).
          </span>
        </>
      }
      onDismiss={handleCancel}
      onConfirm={handleConfirm}
      isOpen
      ctaPrimaryText="Rename files"
    />
  );
};
