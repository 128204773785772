import { Close, Pause, Skip } from '@air/next-icons';
import { UploadStatus } from '@air/redux-uploader';
import { memo } from 'react';
import isEqual from 'react-fast-compare';

import { FileTrackingItem } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { StatusTrackingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneList/StatusTrackingAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItem } from '~/components/FileStatusTrackingPane/PaneItem';
import {
  getHiddenUntilHoverStyles,
  paneListItemStyles,
  UploaderPaneListItemSharedProps,
} from '~/components/FileStatusTrackingPane/shared';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { getSubtitleWithProgress } from '~/components/FileStatusTrackingPane/utils';

export interface ProgressingPaneListItemProps extends UploaderPaneListItemSharedProps {
  onCancel?: (item: FileTrackingItem) => void;
  onPause?: (item: FileTrackingItem) => void;
  onSkip?: (item: FileTrackingItem) => void;
}

export const ProgressingPaneListItem = memo(
  ({
    item,
    item: {
      title,
      ext,
      status,
      onRowClick,
      previewLoader,
      thumbnailSource,
      avatar,
      subtitle,
      sizeInBytes,
      currentBytesUploaded,
    },
    onSkip,
    onCancel,
    onPause,
  }: ProgressingPaneListItemProps) => (
    <PaneItem
      onClick={onRowClick}
      previewLoader={previewLoader}
      tx={{
        ...paneListItemStyles,
        ...getHiddenUntilHoverStyles({ isCompleted: false, shouldAlwaysShowButtons: false, isClickable: !!onRowClick }),
      }}
      avatar={avatar ?? <StatusTrackingAvatar ext={ext} status={status} thumbnailSource={thumbnailSource} />}
      title={<StatusTrackingTitle>{title}</StatusTrackingTitle>}
      subtitle={
        status === UploadStatus.uploading ? (
          <StatusTrackingSubtitle>
            {subtitle ??
              getSubtitleWithProgress({
                title: 'Uploading...',
                uploadedBytes: currentBytesUploaded,
                sizeInBytes,
              })}
          </StatusTrackingSubtitle>
        ) : (
          <StatusTrackingSubtitle>Queued</StatusTrackingSubtitle>
        )
      }
      buttons={
        <>
          {onPause && <PaneButton Icon={Pause} onClick={() => onPause(item)} label="Pause" />}
          {onSkip && <PaneButton onClick={() => onSkip(item)} Icon={Skip} label="Skip" />}
          {onCancel && <PaneButton onClick={() => onCancel(item)} Icon={Close} label="Cancel" />}
        </>
      }
    />
  ),
  isEqual,
);
ProgressingPaneListItem.displayName = 'ProgressingPaneListItem';
