import { Close, Play, Skip } from '@air/next-icons';
import { memo } from 'react';
import isEqual from 'react-fast-compare';

import { FileTrackingItem } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { StatusTrackingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneList/StatusTrackingAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItem } from '~/components/FileStatusTrackingPane/PaneItem';
import { StatusTrackingSubtitle } from '~/components/FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '~/components/FileStatusTrackingPane/StatusTrackingTitle';
import { getSubtitleWithProgress } from '~/components/FileStatusTrackingPane/utils';

import { getHiddenUntilHoverStyles, paneListItemStyles, UploaderPaneListItemSharedProps } from '../shared';

export interface PausedPaneListItemProps extends UploaderPaneListItemSharedProps {
  onCancel?: (item: FileTrackingItem) => void;
  onResume?: (item: FileTrackingItem) => void;
  onSkip?: (item: FileTrackingItem) => void;
}

export const PausedPaneListItem = memo(
  ({
    onSkip,
    onCancel,
    onResume,
    item,
    item: {
      title,
      onRowClick,
      previewLoader,
      avatar,
      thumbnailSource,
      subtitle,
      sizeInBytes,
      ext,
      currentBytesUploaded,
      status,
    },
  }: PausedPaneListItemProps) => (
    <PaneItem
      onClick={onRowClick}
      previewLoader={previewLoader}
      tx={{
        ...paneListItemStyles,
        ...getHiddenUntilHoverStyles({ isCompleted: false, shouldAlwaysShowButtons: true, isClickable: !!onRowClick }),
      }}
      avatar={avatar ?? <StatusTrackingAvatar ext={ext} status={status} thumbnailSource={thumbnailSource} />}
      title={<StatusTrackingTitle>{title}</StatusTrackingTitle>}
      subtitle={
        <StatusTrackingSubtitle>
          {subtitle ?? getSubtitleWithProgress({ title: 'Paused', uploadedBytes: currentBytesUploaded, sizeInBytes })}
        </StatusTrackingSubtitle>
      }
      buttons={
        <>
          {onResume && <PaneButton onClick={() => onResume(item)} Icon={Play} label="Resume" />}
          {onSkip && <PaneButton onClick={() => onSkip(item)} Icon={Skip} label="Skip" />}
          {onCancel && <PaneButton onClick={() => onCancel(item)} Icon={Close} label="Cancel" />}
        </>
      }
    />
  ),
  isEqual,
);
PausedPaneListItem.displayName = 'PausedPaneListItem';
