import { UploadStatus } from '@air/redux-uploader';
import { TXProp } from '@air/zephyr';
import { hideVisually } from 'polished';

import { FileTrackingItem } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { SimpleStatus } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/FileStatusTrackingPaneHeader';

export interface UploaderPaneListItemSharedProps {
  item: FileTrackingItem;
}

export interface UploadStatusHeaderTitle {
  title: string;
  subtitle?: string;
}

export type FileTrackingStatusHeaderTitles = { [key in SimpleStatus]: UploadStatusHeaderTitle };

export type ProgressingStatus = UploadStatus.preparing | UploadStatus.uploading | UploadStatus.queued;

export interface HeaderStatusParams {
  pausedCount: number;
  erroredCount: number;
  completedCount: number;
  progressingCount: number;
}

export const progressingStatuses: UploadStatus[] = [
  UploadStatus.preparing,
  UploadStatus.uploading,
  UploadStatus.queued,
];

const undoHideVisually = {
  position: 'initial',
  border: 'initial',
  clip: 'initial',
  height: 'initial',
  margin: 'initial',
  overflow: 'initial',
  padding: 'initial',
  width: 'initial',
  whiteSpace: 'initial',
  wordWrap: 'initial',
};

export const sharedThumbnailStyles: TXProp = {
  display: 'inline-block',
  width: 40,
  height: 40,
  mr: 12,
  flexShrink: 0,
  objectFit: 'cover',
  borderRadius: 2,
};

export const paneListItemStyles: TXProp = { my: 4, px: 10, py: 6, borderRadius: 3 };

const transition = 'opacity 200ms ease, background-color 200ms ease';

export const getHiddenUntilHoverStyles = ({
  isCompleted,
  shouldAlwaysShowButtons,
  isClickable,
}: {
  isCompleted: boolean;
  shouldAlwaysShowButtons: boolean;
  isClickable: boolean;
}): TXProp => ({
  opacity: isCompleted ? 0.6 : 1,
  mx: 6,
  transition,
  cursor: isClickable ? 'pointer' : 'default',
  '&:hover': {
    backgroundColor: isClickable || !isCompleted ? 'pigeon050' : 'initial',
    transition,
  },
  '& .pane-item-buttons': {
    ...(shouldAlwaysShowButtons ? undoHideVisually : hideVisually()),
  },
  '&:hover .pane-item-buttons, .pane-item-buttons:focus-within': {
    ...undoHideVisually,
  },
  '& .pane-item-buttons button': {
    backgroundColor: shouldAlwaysShowButtons ? 'white' : undefined,
    opacity: shouldAlwaysShowButtons ? 1 : 0,
    transition,
  },
  '&:hover .pane-item-buttons button:hover': {
    backgroundColor: shouldAlwaysShowButtons ? 'pigeon100' : undefined,
    transition,
  },
  '&:hover .pane-item-buttons button, & .pane-item-buttons button:focus-visible': {
    backgroundColor: shouldAlwaysShowButtons ? 'pigeon050' : undefined,
    opacity: 1,
    transition,
  },
});
