export interface Task {
  id: number | string;
  getIsBlocked?: () => boolean;
  hasFinished?: boolean;
  hasStarted?: boolean;
  run: (taskId: Task['id']) => void;
}

export interface WorkerOptions {
  onTaskStarted?: (taskId: Task['id']) => void;
  onTaskFinished?: (taskId: Task['id']) => void;
}

export class Worker {
  private id: Task['id'];
  private isBusy: boolean;
  private options: WorkerOptions = {};

  constructor(id: number, options?: WorkerOptions) {
    this.id = id;
    this.options = options || {};
    this.isBusy = false;
  }

  public resetWorker = () => {
    this.isBusy = false;
  };

  public getIsBusy = () => this.isBusy;

  public getWorkerId = () => this.id;

  public async runTask(task: Task) {
    try {
      this.isBusy = true;
      task.hasStarted = true;
      this.options?.onTaskStarted?.(task.id);
      await task.run(this.id);
      this.isBusy = false;
      task.hasFinished = true;
      this.options?.onTaskFinished?.(task.id);
    } catch (error) {
      this.isBusy = false;
      throw error;
    }
  }
}
