import { Button } from '@air/primitive-button';
import { animated, useSpring } from '@react-spring/web';
import pluralize from 'pluralize';
import { ComponentProps, memo, ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { AutoSizer, List, ListRowRenderer } from 'react-virtualized';

const MAX_UPLOAD_HEIGHT = 450;
const HEADER_ROW_HEIGHT = 34;
const HEADER_ROW_TOP_MARGIN = 6;
const UPLOAD_ROW_HEIGHT = 60;

export interface UploaderPaneListProps {
  ongoingItems: ReactNode[];
  erroredItems: ReactNode[];
  completedItems: ReactNode[];
  onRetryAll?: () => void;
  isUploaderOpen: boolean;
}

const ErroredItemsHeader = memo(
  ({
    numberOfItems,
    onRetryAll,
    style,
  }: Pick<ComponentProps<'div'>, 'style'> & {
    numberOfItems: number;
    onRetryAll: UploaderPaneListProps['onRetryAll'];
  }) => (
    <div
      className="mb-1.5 ml-2 mr-4 flex items-center justify-between border-b border-b-pigeon-100 py-2 pl-2 pr-0"
      style={style}
    >
      <div className="text-10 font-bold uppercase tracking-wider text-pigeon-400">
        {pluralize('Errors', numberOfItems, true)}
      </div>

      {onRetryAll && (
        <Button appearance="ghost" color="grey" onClick={onRetryAll} size="small">
          Retry all
        </Button>
      )}
    </div>
  ),
);

ErroredItemsHeader.displayName = 'ErroredItemsHeader';

const CompletedItemsHeader = memo(
  ({ numberOfItems, style }: Pick<ComponentProps<'div'>, 'style'> & { numberOfItems: number }) => (
    <div
      className="mb-1.5 flex h-7 items-center bg-pigeon-25 px-4 text-10 font-bold uppercase tracking-wider text-pigeon-400"
      style={style}
    >
      {numberOfItems} Completed
    </div>
  ),
);

CompletedItemsHeader.displayName = 'CompletedItemsHeader';

enum PaneListRowType {
  item,
  header,
}

interface PaneListRow {
  type: PaneListRowType;
  row: ReactNode;
}

export const FileStatusTrackingPaneList = memo(
  ({ isUploaderOpen, ongoingItems, erroredItems, completedItems, onRetryAll }: UploaderPaneListProps) => {
    const listRef = useRef<List>(null);

    const rows: PaneListRow[] = useMemo(() => {
      const itemsToRender: PaneListRow[] = [];

      if (ongoingItems.length) {
        itemsToRender.push(
          ...ongoingItems.map((item) => ({
            type: PaneListRowType.item,
            row: item,
          })),
        );
      }

      if (erroredItems.length) {
        itemsToRender.push({
          type: PaneListRowType.header,
          row: (
            <ErroredItemsHeader
              numberOfItems={erroredItems.length}
              onRetryAll={onRetryAll}
              style={{ marginTop: itemsToRender.length > 0 ? 6 : 0 }}
            />
          ),
        });
        itemsToRender.push(
          ...erroredItems.map((item) => ({
            type: PaneListRowType.item,
            row: item,
          })),
        );
      }

      if (completedItems.length) {
        itemsToRender.push({
          type: PaneListRowType.header,
          row: (
            <CompletedItemsHeader
              numberOfItems={completedItems.length}
              style={{ marginTop: itemsToRender.length > 0 ? 6 : 0 }}
            />
          ),
        });
        itemsToRender.push(
          ...completedItems.map((item) => ({
            type: PaneListRowType.item,
            row: item,
          })),
        );
      }

      return itemsToRender;
    }, [completedItems, erroredItems, onRetryAll, ongoingItems]);

    const rowRenderer: ListRowRenderer = useCallback(
      ({ index, style, key }) => {
        return (
          <li style={style} key={key}>
            {rows[index].row}
          </li>
        );
      },
      [rows],
    );

    const getRowHeight = useCallback(
      ({ index }: { index: number }) => {
        if (rows[index].type === PaneListRowType.header) {
          return index === 0 ? HEADER_ROW_HEIGHT : HEADER_ROW_HEIGHT + HEADER_ROW_TOP_MARGIN;
        } else {
          return UPLOAD_ROW_HEIGHT;
        }
      },
      [rows],
    );

    const listHeight = useMemo(() => {
      const allRowsHeight = rows.reduce((acc, _, index) => {
        acc += getRowHeight({ index });
        return acc;
      }, 0);
      return Math.min(MAX_UPLOAD_HEIGHT, allRowsHeight);
    }, [getRowHeight, rows]);

    const [springs] = useSpring(
      () => ({
        height: isUploaderOpen ? listHeight : 0,
        opacity: isUploaderOpen ? 1 : 0,
      }),
      [isUploaderOpen, listHeight],
    );

    useEffect(() => {
      listRef?.current?.recomputeRowHeights();
    }, [ongoingItems.length, erroredItems.length, completedItems.length]);

    return (
      <animated.div
        className="m-0 list-none overflow-y-auto overflow-x-hidden"
        style={{
          height: springs.height,
          opacity: springs.opacity,
          maxHeight: MAX_UPLOAD_HEIGHT,
        }}
      >
        {isUploaderOpen ? (
          <AutoSizer disableHeight>
            {({ width }) => (
              <List
                ref={listRef}
                rowRenderer={rowRenderer}
                rowCount={rows.length}
                height={listHeight}
                width={width}
                rowHeight={getRowHeight}
              />
            )}
          </AutoSizer>
        ) : null}
      </animated.div>
    );
  },
);

FileStatusTrackingPaneList.displayName = 'UploaderPaneList';
