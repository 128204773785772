import { UploadStatus } from '@air/redux-uploader';
import { Box } from '@air/zephyr';

import FileExtensionPreview from '~/components/FileExtensionPreview';
import { FileTrackingItemBase } from '~/components/FileStatusTrackingPane/FileStatusTrackingPane/FileStatusTrackingPane';
import { FolderWithStatus } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneList/FolderWithStatus';
import { sharedThumbnailStyles } from '~/components/FileStatusTrackingPane/shared';
import { ImageContainer } from '~/components/UI/NoPreviewImage/ui';

export const StatusTrackingAvatar = ({
  ext = '',
  status,
  thumbnailSource,
}: Pick<FileTrackingItemBase, 'ext' | 'thumbnailSource'> & { status: UploadStatus }) => {
  return thumbnailSource ? (
    <img className="mr-3 inline-block h-10 w-10 shrink-0 rounded-sm object-cover" src={thumbnailSource} alt="" />
  ) : ext === 'folder' ? (
    <FolderWithStatus status={status} />
  ) : (
    <Box
      as={ImageContainer}
      ext={ext}
      processing={false}
      borderRadius={2}
      tx={{ flexGrow: '0 !important', ...sharedThumbnailStyles }}
    >
      <FileExtensionPreview ext={ext} size="small" className="max-w-[40px]" />
    </Box>
  );
};
