import { ConfirmationModal } from '@air/component-confirmation-modal';
import { InputFile } from '@air/redux-uploader';

export interface MissingFileExtensionsModalProps {
  items: InputFile[];
  onCancel?: () => void;
  onConfirm?: () => void;
}

export const MissingFileExtensionsModal = ({
  items,
  onCancel,
  onClose,
  onConfirm,
}: AirModalProps<MissingFileExtensionsModalProps>) => {
  const handleCancel = () => {
    onCancel?.();
    onClose();
  };

  const handleConfirm = () => {
    onConfirm?.();
    onClose();
  };

  return (
    <ConfirmationModal
      title={'Missing file extensions'}
      id="missing-file-extensions"
      description={
        items.length < 25 ? (
          <>
            <span>The following files have no extension and cannot be uploaded:</span>
            <ul className="m-4 list-disc">
              {items.map((item, index) => (
                <li key={index}>{item.name}</li>
              ))}
            </ul>
            <span>Do you want to continue uploading without these files or cancel the upload?</span>
          </>
        ) : (
          <>
            <span>
              Some of your files do not have extensions and cannot be uploaded. Do you want to continue uploading
              without these files or cancel the upload?
            </span>
          </>
        )
      }
      onDismiss={handleCancel}
      onConfirm={handleConfirm}
      isOpen
      ctaSecondaryText="Cancel"
      ctaPrimaryText="Continue"
    />
  );
};
