import { UploadStatus } from '@air/redux-uploader';
import { Box } from '@air/zephyr';
import { memo } from 'react';

import { ReactComponent as FolderError } from '~/components/FileStatusTrackingPane/folder-with-error.svg';
import { ReactComponent as FolderPause } from '~/components/FileStatusTrackingPane/folder-with-pause.svg';
import { ReactComponent as FolderProgress } from '~/components/FileStatusTrackingPane/folder-with-progress.svg';
import { ReactComponent as FolderSuccess } from '~/components/FileStatusTrackingPane/folder-with-success.svg';

import { sharedThumbnailStyles } from '../shared';

export interface FolderWithStatusProps {
  status: UploadStatus;
}

export const FolderWithStatus = memo(({ status }: FolderWithStatusProps) => {
  const isCompleted = status === UploadStatus['completed'];
  if (isCompleted) return <Box as={FolderSuccess} tx={sharedThumbnailStyles} />;

  const isPaused = status === UploadStatus['paused'];
  if (isPaused) return <Box as={FolderPause} tx={sharedThumbnailStyles} />;

  const isErrored = [UploadStatus['failed'], UploadStatus['rejected']].includes(status);
  if (isErrored) return <Box as={FolderError} tx={sharedThumbnailStyles} />;

  return <Box as={FolderProgress} tx={sharedThumbnailStyles} />;
});

FolderWithStatus.displayName = 'FolderWithStatus';
